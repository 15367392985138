import projectInfo from '../../package.json';
import meta from '../metadata.json';

declare global {
  interface Window {
    config: IConfig;
  }
}

// prepare config
const local = window.location.href.indexOf('localhost') !== -1;
const test = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' || process.env.REACT_APP_DEV === '1' || local;

const out: IConfig = { ...window.config };

// version
out.version = projectInfo.version + ', build: ' + meta.build;

// test settings
if (test) {
  out.debug = window.location.search.indexOf('debug') !== -1;
  if (local) {
    out.localhost = true;
    out.apiUrl = 'http://127.0.0.1:3002';
    // out.apiUrl = 'http://10.15.101.36:3002';
  }

  console.log('env: test');
  console.log('API url: ' + out.apiUrl);
}

console.log('version: ' + out.version);
if (out.debug) {
  console.log('debug mode');
}

export const config = { ...out };

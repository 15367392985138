import { useAppSelector, USER_FEATURE_KEY, IUserState } from 'libs/data-store';
import { Logo } from 'libs/layout';
import { User, MainMenu } from 'libs/ui';
import { StyledAppBar, StyledToolbar, Left, Right } from './header.styled';

export const Header = () => {
  const user: IUserState = useAppSelector((state: any) => state[USER_FEATURE_KEY]);
  //console.log('🚀 ~ Header ~ user:', user);
  const items = [
    { title: 'Список', link: '/' },
    { title: 'Карта', link: '/map' },
  ];
  if (user.admin) {
    items.push({ title: 'Тарифы', link: '/tariffs' });
  }

  return (
    <StyledAppBar position="sticky">
      <StyledToolbar>
        <Left>
          <Logo />
          {user.userLogined && <MainMenu items={items} />}
        </Left>
        <Right>
          <User />
        </Right>
      </StyledToolbar>
    </StyledAppBar>
  );
};

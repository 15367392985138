import { useAppSelector, USER_FEATURE_KEY } from 'libs/data-store';
import { StyledLogo } from './logo.styled';

export const Logo = () => {
  const user = useAppSelector((state) => state[USER_FEATURE_KEY]);

  const to = user.userLogined ? '/' : '/login';

  return <StyledLogo to={to}>Статус устройств</StyledLogo>;
};

export default Logo;

import styled from '@emotion/styled';
//import { css } from '@emotion/react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

export const StyledAppBar = styled(AppBar)``;
export const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
`;

export const Left = styled.div`
  margin: 0 1rem 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Right = styled.div`
  color: #959ba4;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & .MuiIconButton-label {
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
`;

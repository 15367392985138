import { config } from 'app/config';
import { store, interfaceActions } from 'libs/data-store';
import { notify } from 'libs/common';
import { userService } from 'libs/services';
import Api from './UdsApi';
import { getApiError } from './func';

let lastMethod = '';
const ERROR = 'error';
const withoutLoader = ['remote/events'];

const setLoader = (load) => {
  store.dispatch(interfaceActions.setLoading(load));
  return load;
};

/**
 * set auth token if needed
 * @param {*} req
 */
const setAuth = (req) => {
  //console.log('🚀 ~ setAuth ~ lastMethod', lastMethod);
  if (req.url !== 'login') {
    const token = userService.getToken();
    //console.log('🚀 ~ setAuth ~ token', token);
    if (token && token.token) {
      req.headers['authorization'] = token.token;
    }
  }
  return req;
};

/**
 * intercept request
 * @param {*} req
 * @returns
 */
const prepareRequest = (req) => {
  //console.log('🚀 ~ req', req);

  lastMethod = req.url;
  //console.log('🚀 ~ prepareRequest ~ lastMethod:', lastMethod);
  if (!withoutLoader.includes(lastMethod)) {
    setLoader(true);
  }

  // packet
  //req = getJSONRPCPacket(req);

  // auth
  req = setAuth(req);
  return req;
};

/**
 * intercept error
 * @param {*} error
 * @returns
 */
const prepareError = (error) => {
  setLoader(false);
  let msg;
  //console.log('🚀 ~ prepareerror', error);

  // response error
  //console.log('🚀 ~ prepareError ~ msg', error);
  if (error.response) {
    if (error.response.status === 403) {
      userService.logout();
      return;
    }

    msg = error.response.data && error.response.data.message ? error.response.data.message : getApiError(error.response.status);
    if (error.response.status !== 400) {
      notify('Ошибка соединения', msg);
    }
  } else {
    msg = getApiError(error.code);
    notify('Ошибка соединения', msg);
  }
  //console.log('🚀 ~ prepareError ~ msg', msg);

  return Promise.reject(msg);
};

/**
 * decode packet
 * @param {*} res
 * @returns
 */
const decodePacket = (res) => {
  let out = {};
  let error = false;

  // no auth
  //console.log('🚀 ~ decodeJSONRPCPacket ~ res.status', lastMethod);
  if (res.status === 403 && lastMethod !== 'auth/login') {
    // 401?
    userService.logout();
    return { packet: {}, error: true };
  }

  const data = res.data;
  //console.log('🚀  ~ res', data);

  if (data.error) {
    if (data.error.code === 403) {
      if (userService.isLogined()) {
        userService.logout();
        return { packet: {}, error: true };
      }
    }
    //if (data.error.code === -32601) {
    //notify('Ошибка API', JSON.stringify(data.error));
    //}
    let msg = data.error.message;

    out[ERROR] = msg; //getError(data.error.code, 'errors', data.error.message);
    error = true;
  } else {
    out = data;
  }

  return { packet: out, error };
};

/**
 * intercept respose
 * @param {*} res
 * @returns
 */
const prepareResponse = (res) => {
  //console.log('🚀 ~ prepareResponse ~ res', res);
  setLoader(false);
  const out = decodePacket(res);
  //console.log('🚀 ~ prepareResponse ~ out', out);

  if (out.error) {
    return Promise.reject(out.packet);
  }
  return out.packet;
};

export const api = new Api({ apiUrl: config.apiUrl, prepareRequest, prepareResponse, prepareError });

export default api;

import { hmac } from 'libs/common';

const authName = 'auth';
//const userName = 'user';

/**
 * service for interaction with user api
 * 1. get token from local storage
 * 2. if exists - continue to next
 * 3. if not exists or 403 on auth/me - login
 *
 * @class UserService
 */

class UserService {
  /**
   *
   * @param {*} param0 = {
   *  localStorage - local storage api -
   *  api - backend api
   *  actions - notify, setAuth, unsetAuth, setUser
   * }
   */
  constructor({ api, localStorage, actions }) {
    this.localStorage = localStorage;
    this.api = api;
    this.actions = actions;

    // get current state
    this.state = this.localStorage.getItem();
    this.logined = false;
    this.role = '';
  }

  /**
   * 1. Check user token
   *
   */
  async checkToken() {
    //console.log('🚀 ~ checkToken ~ this.state', this.state);
    if (!this.state[authName]) {
      this.actions.unsetAuth();
      this.localStorage.clear();
      this.logined = false;
      return;
    }

    //await this.getUser();
    this.logined = true;
    this.actions.setAuth(this.state[authName]);
  }

  /**
   * Login request
   * @param {*} username
   * @param {*} password
   * @returns promise
   */
  async login({ login, password }) {
    let err = false;
    let errResult = '';
    const auth = await this.api.call('login', { login, password: hmac(password) }).catch((result) => {
      err = true;
      errResult = result;
    });

    if (err) {
      return { error: errResult };
    }

    //console.log('🚀 ~ auth ~ err', err);

    this.localStorage.setItem(authName, auth);
    this.state[authName] = auth;
    this.actions.setAuth(auth);
    this.logined = true;

    return auth;
  }

  /**
   * logout request
   */
  logout() {
    this.actions.unsetAuth();
    this.localStorage.clear();
    this.logined = false;
    window.continueLink = '';
    window.continueLinkAfterLogout = true;
  }

  isLogined() {
    return this.logined;
  }

  getToken() {
    return this.state[authName] ?? '';
  }
}

export default UserService;

import { lazy } from 'react';

// main page
export const PageMain = lazy(() =>
  import('app/pages/main').then(({ PageMain }) => ({
    default: PageMain,
  }))
);

export const PageLogin = lazy(() =>
  import('app/pages/login').then(({ PageLogin }) => ({
    default: PageLogin,
  }))
);

export const PageParkomat = lazy(() =>
  import('app/pages/parkomat').then(({ PageParkomat }) => ({
    default: PageParkomat,
  }))
);

export const PageMap = lazy(() =>
  import('app/pages/map').then(({ PageMap }) => ({
    default: PageMap,
  }))
);

export const PageTariffs = lazy(() =>
  import('app/pages/tariffs').then(({ PageTariffs }) => ({
    default: PageTariffs,
  }))
);

export const PageZones = lazy(() =>
  import('app/pages/tariffs').then(({ PageZones }) => ({
    default: PageZones,
  }))
);

export const PageVT = lazy(() =>
  import('app/pages/tariffs').then(({ PageVT }) => ({
    default: PageVT,
  }))
);

export const PageHistory = lazy(() =>
  import('app/pages/history').then(({ PageHistory }) => ({
    default: PageHistory,
  }))
);

export const PageEvents = lazy(() =>
  import('app/pages/events').then(({ PageEvents }) => ({
    default: PageEvents,
  }))
);

import { useAppSelector, INTERFACE_FEATURE_KEY, IInterfaceState } from 'libs/data-store';
import { LayoutCommon, defaultTheme, Container, Header, Footer } from 'libs/layout';
import { Main } from './layout-base.styled';

export type ILayoutBase = {
  children: JSX.Element;
  vcenter: boolean;
  theme?: any;
};

export function LayoutBase(props: ILayoutBase) {
  const { children, theme, vcenter } = props;
  const data: IInterfaceState = useAppSelector((state: any) => state[INTERFACE_FEATURE_KEY]);

  return (
    <LayoutCommon theme={theme ?? defaultTheme} loading={data.loading}>
      <Main>
        <Header />
        <Container vcenter={vcenter}>{children}</Container>
        <Footer />
      </Main>
    </LayoutCommon>
  );
}

LayoutBase.defaultProps = {
  vcenter: false,
};

export default LayoutBase;

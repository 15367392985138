/* eslint-disable no-loop-func */
import { useEffect } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import Router from './router/router';
import { useAppSelector, USER_FEATURE_KEY } from 'libs/data-store';
import { userService } from 'libs/services';

export function App() {
  // check user token
  useEffect(() => {
    userService.checkToken();
  }, []);

  // check results
  const user = useAppSelector((state: any) => state[USER_FEATURE_KEY]);
  if (!user.userChecked) {
    return null;
  }

  return (
    <>
      <ReactNotifications />
      <Router />
    </>
  );
}

export default App;

/**
 * parkomat logs service
 *
 * @summary implements API calls
 */
import { api } from 'api';
//import { notify, LocalStorage } from 'libs/common';

class LogService {
  /**
   * get parkomats
   * @returns
   */
  async getState() {
    return await api.call('state');
  }

  /**
   * get parkomat
   * @param id
   * @returns
   */
  async getParkomatState(id: string) {
    return await api.call('state/' + id);
  }

  /**
   * restart parkomat
   * @param ip
   * @returns
   */
  async restartParkomat(ip: string) {
    return await api.call('remote/restart', { ip });
  }

  /**
   * get parkomat history
   * @param id
   * @returns
   */
  async getParkomatHistory(id: string, from: number = 0, limit: number = 10) {
    const res: Record<any, any> = await api.call('state/' + id + '/history?from=' + from + '&limit=' + limit);
    return { items: res[0], count: res[1] };
  }

  /**
   * update remote tariffs
   * @returns
   */
  async updateParkomatsTariffs() {
    return await api.call('remote/tariffs');
  }

  /**
   * get parkomat events
   * @param ip
   * @returns
   */
  async getParkomatEvents(ip: string) {
    const res: Record<any, any> = await api.call('remote/events', { ip });
    return { items: res, count: res.length };
  }
}

export const logService = new LogService();
export default logService;

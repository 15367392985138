import { Button, IButtonProps, Confirm } from 'libs/ui';
import { useConfirm } from 'libs/hooks';
import { FunctionComponent } from 'react';

export type IAction = {
  title: string;
  withConfirm?: boolean;
  confirmTitle?: string;
  action?: () => void;
  last?: boolean;
} & IButtonProps;

export const Action: FunctionComponent<IAction> = ({ confirmTitle = 'Выполнить действие?', size = 'small', last, title, withConfirm, action, ...rest }) => {
  //const { last, title, confirmTitle, withConfirm, action, ...rest } = props;
  const [confirm, showConfirm] = useConfirm({ title: confirmTitle, action: action, actionTitle: title, Confirm });

  const other: any = {
    size,
  };
  if (!last) {
    other.sx = { mr: '5px' };
  }
  const doAction = () => {
    if (withConfirm) {
      showConfirm();
    } else {
      action && action();
    }
  };

  if (!rest.to) {
    other.onClick = doAction;
  }

  return (
    <>
      <Button {...rest} {...other}>
        {title}
      </Button>
      {confirm}
    </>
  );
};

export default Action;

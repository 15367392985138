import { useState } from 'react';
import MUITable, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { useStateStorage } from 'libs/hooks';
import TableHeader from './table-header';
import TableRow from './table-row';
import { TableFooter } from './table.styled';
import { stableSort, getComparator } from './table-func';
import { Loader, Confirm } from 'libs/ui';

/**
 * table component
 */
export function Table(props: ITable) {
  const {
    footer,
    minWidth,
    title,
    defaultOrder,
    defaultOrderBy,
    fields,
    rows,
    actions,
    onRowClick,
    onRowClickLinks,
    storage,
    getRowColor,
    getRowPopup,
    dense,
    getRowLink,
  } = props;
  const [order, setOrder] = useStateStorage('order', defaultOrder, storage);
  const [orderBy, setOrderBy] = useStateStorage('orderBy', defaultOrderBy, storage);
  const [showConfirm, setShowConfirm] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const handleRequestSort = (__event: React.MouseEvent<unknown>, field: string) => {
    const isAsc = orderBy === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(field);
  };

  if (!rows) {
    return <Loader />;
  }

  if (!rows.count) {
    return <p>Записи отсутствуют</p>;
  }

  /**
   * delete action with confirm
   * @param row
   * @returns
   */
  const deleteAction = (row: any) => {
    const action = props.deleteAction;
    if (!action) {
      return;
    }

    let title = 'Удалить запись';
    if (row.title) {
      title += ' ' + row.title;
    }

    setShowConfirm({
      active: true,
      confirmText: title,
      confirmAction: () => {
        action(
          row,
          () => {
            closeConfirm();
          },
          (error: any) => {
            const out = typeof error === 'object' ? JSON.stringify(error) : error;
            //console.log('error', error);
            setError(out);
          }
        );
      },
    });
  };

  /**
   * find field by name
   * @param fn
   * @returns
   */
  const getField = (fn: string) => {
    const out = fields.find((el) => el.name === fn);
    return out ? out : fields[0];
  };

  const closeConfirm = () => {
    setShowConfirm(null);
  };

  const withDelete = !!props.deleteAction;
  const rowParams: any = { onRowClick, onRowClickLinks, getRowLink, actions, fields, getRowColor, getRowPopup };
  if (withDelete) {
    rowParams.deleteAction = deleteAction;
  }

  const tableProps: TableProps = {};
  if (dense) {
    tableProps.size = 'small';
  }

  return (
    <>
      <TableContainer>
        <MUITable sx={{ minWidth }} aria-labelledby={title} {...tableProps}>
          <TableHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} fields={fields} actions={actions} withDelete={withDelete} />
          <TableBody>
            {stableSort(rows.items, getComparator(order, orderBy, getField(orderBy))).map((row, idx) => {
              return <TableRow key={idx} row={row} {...rowParams} />;
            })}
          </TableBody>
        </MUITable>
        {footer && <TableFooter>{footer}</TableFooter>}
      </TableContainer>
      {showConfirm && showConfirm.active && (
        <Confirm
          error={error}
          open={true}
          close={closeConfirm}
          title="Подтвердите действие"
          info={showConfirm.confirmText}
          action={showConfirm.confirmAction}
          actionTitle="Подтвердить"
        />
      )}
    </>
  );
}
Table.defaultProps = {
  minWidth: 750,
  title: '',
  defaultOrder: 'asc',
  defaultOrderBy: '',
  rowsPerPage: 10,
  rowsPerPageOptions: [5, 10, 25],
};

export default Table;

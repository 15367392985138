import jsSHA from 'jssha';

/**
 * return hmac sha3-512 of password
 *
 * @param {*} password
 */
export const hmac = (password: string) => {
  /*
    const shaObj = new jsSHA('SHA3-512', 'TEXT', {
        hmacKey: { value: 'bd3eca38a50984ee8a6e7f380190cfc4ca97961929ee87398ac10d8a56528ae699b16a5ac47fbf1000bb4c41bdb20515e6edd32bf4e3208ada91c2da764369c8', format: 'TEXT' }
    });
    shaObj.update(password);
    */
  const shaObj = new jsSHA('SHA-256', 'TEXT', { encoding: 'UTF8' });
  shaObj.update(password);
  return shaObj.getHash('HEX');
};

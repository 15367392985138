import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { links } from 'libs/data-config';
import { LayoutBase } from 'libs/layout';
import { useAppSelector, USER_FEATURE_KEY, IUserState } from 'libs/data-store';
import { Content } from './layout-public.styled';

interface IProps {
  children: JSX.Element;
  toLoginedPath: string;
}

export function LayoutPublic(props: IProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoginPath = location.pathname === links.login;
  //console.log('🚀 ~ LayoutPublic ~ location', location);
  const user: IUserState = useAppSelector((state: any) => state[USER_FEATURE_KEY]);

  useEffect(() => {
    if (user.userLogined && isLoginPath) {
      console.log('🚀 navigate to logined', props.toLoginedPath);
      navigate(props.toLoginedPath);
    }
  }, [navigate, props.toLoginedPath, user.userLogined, isLoginPath]);

  return (
    <LayoutBase vcenter>
      <Content>{props.children}</Content>
    </LayoutBase>
  );
}

export default LayoutPublic;

/**
 * User service
 *
 * @summary implements API calls
 */
import { api } from 'api';
import { notify, LocalStorage } from 'libs/common';
import { store, userActions } from 'libs/data-store';
import UserService from './user-service.class';

const actions = {
  notify: notify,
  setAuth: (auth: any) => store.dispatch(userActions.setAuth(auth)),
  unsetAuth: () => store.dispatch(userActions.unsetAuth()),
};

export interface ILoginParams {
  email: string;
  password: string;
}


const localStorage = new LocalStorage('user');
export const userService = new UserService({ api, localStorage, actions });
export default userService;
//import { ReactNode } from 'react';
import { DelIcon } from 'libs/icons';
import { Place, Value, DeleteButton, Def, Error } from './input.styled';

/* eslint-disable-next-line */
export interface IInputProps {
  value: string;
  title?: string;
  small?: boolean;
  error?: string;
  onChange(value: string): void;
}

export function Input(props: IInputProps) {
  const { value, title, onChange, small, error } = props;

  const deleteChar = () => {
    if (value.length > 0) {
      const newValue = value.substring(0, value.length - 1);
      onChange(newValue);
    }
  };

  return (
    <Place>
      {value ? <Value small={small}>{value}</Value> : <Def>{title}</Def>}
      {error && <Error>{error}</Error>}
      <DeleteButton onClick={deleteChar}>
        <DelIcon />
      </DeleteButton>
    </Place>
  );
}

export default Input;

import { Store } from 'react-notifications-component';
import moment from 'moment';
import 'moment/locale/ru';

import { config } from 'app/config';

moment.locale('ru');
moment.suppressDeprecationWarnings = true;

export function isEmptyObject(obj: Record<string, unknown>): boolean {
  return Object.getOwnPropertyNames(obj).length === 0;
}

export function getRand(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const notify = (title: string, msg: string = '', other?: any) => {
  other = {
    ...{
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: { duration: 3000, onScreen: true },
    },
    ...other,
  };

  if (!title) {
    title = 'Error';
  }

  if (typeof msg !== 'string') {
    msg = JSON.stringify(msg);
  }

  if (!msg) {
    msg = 'Неизвестная ошибка';
  }

  const params = {
    ...other,
    title: title,
    message: msg,
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismissable: { click: true },
  };

  //console.log('notify', params);
  Store.addNotification(params);
};

export const fakeWait = (counts: number) => {
  let k;
  const cnt = counts * 100000;
  for (let i = 0; i < cnt; i++) {
    k = i ** 9;
  }
  return k;
};

/**
 *
 * @param time ms
 * @returns
 */
export const wait = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(time), time);
  });

export const getJsonRPCPacket = (method: string, data: any) => {
  return {
    jsonrpc: '2.0',
    id: +new Date(),
    method,
    params: data,
  };
};

export const isEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

/**
 * date time functions
 */
export const stringToDate = (time?: any, format = 'D MMMM YYYY') => {
  const out = moment(time);
  out.locale('ru');
  return out.format(format);
};

export const unixToDate = (time: number, format = 'D MMMM YYYY HH:mm:ss') => {
  const out = moment.unix(time);
  out.locale('ru');
  return out.format(format);
};

/**
 * other
 */
export const prepareLine = (line: string) => {
  return line ? line.replaceAll('"', '').trim() : '';
};

/**
 * check row online
 * @param el
 * @returns
 */
export const isOnline = (el: any): boolean => {
  if (!el) {
    return true;
  }
  if (!el.lastOnlineDateTime) {
    return true;
  }
  const now = parseInt(moment().format('X'));
  const checkSeconds = config.testParkomats?.includes(el.id.toString()) ? 60 : config.failAfter;
  //console.log('🚀 ~ isOnline ~ checkSeconds:', checkSeconds);
  return now - el.lastOnlineDateTime < checkSeconds;
};

/**
 * prepare coords
 * @param val
 * @returns
 */
export const prepareCoords = (val: string) => {
  val = val.trim();
  if (val === '00.00000,00.00000') {
    val = '';
  }
  return val;
};

/**
 * get charge value in percent
 * @param el
 */
export const getChargePr = (charge: any) => {
  if (!charge || charge === '-') {
    return 0;
  }
  let val = charge.split('|');
  return parseInt(val[0]);
};

/**
 * check devie status
 * @param el
 * @returns
 */
export const isDeviceDanger = (device: any, info?: Array<string>, current?: IStatusColor): IStatusColor => {
  let r: IStatusColor = current || 'green';

  // red if pax problem
  if (device.name === 'Терминал PAX' && device.status === 'Ошибка подключения') {
    info && info.push('pax error in devices');
    r = 'red';
  }

  // yellow if errors in devices
  if (r !== 'red' && r !== 'yellow' && device.name !== 'Терминал PAX') {
    if (device.status === 'Ошибка подключения') {
      r = 'yellow';
      info && info.push('Ошибка подключения in devices');
    } else if (device.status === 'Подключение установлено') {
      if (device.info.indexOf('Требуется осмотр') !== -1 || device.info.indexOf('открыта') !== -1) {
        info && info.push('Требуется осмотр или открыта in devices');
        r = 'yellow';
      }
    }
  }

  return r;
};

/**
 * red line in table
 * ошибка БТ или не в сети
 * заряд меньше 20
 * @param el
 * @returns
 */
export const isParkomatDanger = (el: any): boolean => {
  let r = false;

  // online
  if (!r && !isOnline(el)) {
    r = true;
  }

  // charge
  if (!r && el.charge && el.charge !== '-' && getChargePr(el.charge) < 20) {
    r = true;
  }

  // bt status in parkomat
  let test = el.status ? el.status.toLowerCase() : '';
  if (!r && test && test.indexOf('PAX') !== -1) {
    r = true;
  }

  // bt status in devices
  if (!r) {
    if (el.devices) {
      const devices = typeof el.devices === 'string' ? JSON.parse(el.devices) : el.devices;
      devices.forEach((device: any) => {
        if (!r) {
          if (device.name === 'Терминал PAX' && device.status === 'Ошибка подключения') {
            r = true;
          }
        }
      });
    }
  }

  return r;
};

/**
 * get status color
 * @param el
 * @returns green | red | yellow
 */
export const getStatusColor = (el: any): IStatusColor => {
  const debug = config.debug;
  const info: Array<string> = [];
  let r: IStatusColor = 'green';

  // online
  if (!isOnline(el)) {
    info.push('offline');
    r = 'red';
  }

  // charge
  if (r === 'green' && el.charge && el.charge !== '-') {
    const charge = getChargePr(el.charge);
    if (charge < 20) {
      info.push('charge < 20');
      r = 'red';
    } else if (charge >= 20 && charge <= 30) {
      r = 'yellow';
      info.push('20 < charge < 30');
    }
  }

  // bt status in parkomat
  if (r === 'green' || r === 'yellow') {
    const test = el.status ? el.status.toLowerCase() : '';
    if (!r && test && test.indexOf('PAX') !== -1) {
      info.push('pax in status');
      r = 'red';
    }
  }

  // bt status in devices
  if (r === 'green' || r === 'yellow') {
    if (el.devices) {
      const devices = typeof el.devices === 'string' ? JSON.parse(el.devices) : el.devices;
      devices.forEach((device: any) => {
        r = isDeviceDanger(device, info, r);
      });
    }
  }
  if (debug && r !== 'green') {
    console.log(r, info);
  }

  return r;
};

const statusColorTypes: Record<IStatusColor, number> = {
  green: 0,
  yellow: 1,
  red: 2,
};
const statusColorNumbers: Record<number, IStatusColor> = {};
Object.keys(statusColorTypes).forEach((key: string) => {
  const color = key as IStatusColor;
  statusColorNumbers[statusColorTypes[color]] = color;
});

/**
 * get number for status color
 * @param color
 * @returns
 */
export const getStatusColorNumber = (color: IStatusColor): number => {
  return statusColorTypes[color];
};

/**
 * get status color by number
 * @param val
 * @returns
 */
export const getStatusColorByNumber = (val: number): IStatusColor => {
  return statusColorNumbers[val];
};

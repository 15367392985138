import { FieldProps } from 'formik';
import { ReactNode } from 'react';

import { DateTimeInput, DateTimeInputProps } from 'libs/ui';

export const DateTimeField = (props: FieldProps & DateTimeInputProps): ReactNode => {
  const { field, form, ...rest } = props;
  const change = (value: any) => {
    form.setFieldValue(field.name, value);
  };

  return <DateTimeInput {...field} {...rest} onChange={change} />;
};

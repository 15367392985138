import { Field as FormikField, FieldInputProps } from 'formik';

export interface IFieldProps {
  name: string;
  label: string;
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field?: FieldInputProps<any>,
  component: any;
}

export function Field(props: IFieldProps) {
  const { component, ...rest } = props;
  //console.log('🚀 ~ Field ~ props', props);
  
  return <FormikField component={component} {...rest} />;
}

export default Field;

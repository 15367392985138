/**
 * parkomat tariffs service
 *
 * @summary implements API calls
 */
import { api } from 'api';
//import { notify, LocalStorage } from 'libs/common';

class TariffsService {
  /**
   * get tariffs
   * @returns
   */
  async getTariffs() {
    return await api.call('tariffs/');
  }

  /**
   * update tariff
   * @returns
   */
  async updateTariff(tariff: any) {
    return await api.call('tariffs/update', tariff);
  }

  /**
   * get zones
   * @returns
   */
  async getZones() {
    return await api.call('tariffs/zones');
  }

  /**
   * update zone
   * @returns
   */
  async updateZone(data: any) {
    return await api.call('tariffs/zones/update', data);
  }

  /**
   * add zone
   * @returns
   */
  async addZone(data: any) {
    return await api.call('tariffs/zones/add', data);
  }

  /**
   * delete zone
   * @returns
   */
  async deleteZone(id: number) {
    return await api.call('tariffs/zones/delete', { id });
  }

  /**
   * get vt
   * @returns
   */
  async getVehicleTypes() {
    return await api.call('tariffs/vehicle-types');
  }

  /**
   * update vt
   * @returns
   */
  async updateVehicleType(data: any) {
    return await api.call('tariffs/vehicle-types/update', data);
  }
}

export const tariffsService = new TariffsService();
export default tariffsService;

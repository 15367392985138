import { Form, Field, TextField, CheckboxField, RangeField, DateTimeField, SelectField } from 'libs/form';
import { Button } from 'libs/ui';

import { Content, Actions, Item } from './table-filter.styled';

export const TableFilter = (props: TableFilterProps) => {

  /**
   * get component
   * @param type
   * @returns
   */
  const getCmp = (type: string) => {
    switch (type) {
      case 'checkbox':
        return CheckboxField;
      case 'range':
        return RangeField;
      case 'datetime':
        return DateTimeField;
      case 'select':
        return SelectField;
      default:
        return TextField;
    }
  };

  const items = props.filters.map((el, idx) => {
    const { type, name, title, ...rest } = el;
    const cmp = getCmp(type);
    return (
      <Item key={idx}>
        <Field name={name} label={title} component={cmp} {...rest} />
      </Item>
    );
  });

  return (
    <Content>
      <Form initialValues={props.initialFilter} onSubmit={props.onChange} hideLoading>
        {items}
        <Actions>
          <Button type="submit" color="primary" size="medium" className="filter-btn">
            Ok
          </Button>
        </Actions>
      </Form>
    </Content>
  );
};

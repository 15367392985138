import Slider, { SliderProps } from '@mui/material/Slider';

export type IRangeSelectorProps = SliderProps & {
  onChanged?: (val: number | number[]) => void;
};

export function RangeSelector(props: IRangeSelectorProps) {
  const { onChange, onChanged, ...other } = props;

  // eslint-disable-next-line @typescript-eslint/ban-types
  const change = (event: Event, value: number | number[], activeThumb: number) => {
    if (onChanged) {
      onChanged(value);
    }
    if (onChange) {
      onChange(event, value, activeThumb);
    }
  };

  return (
    <Slider
      {...other}
      onChange={change}
      //defaultValue={30}
      //getAriaValueText={valuetext}
      //aria-labelledby="discrete-slider"
      valueLabelDisplay="auto"
      //step={10}
      //marks
      //min={10}
      //max={110}
    />
  );
}
RangeSelector.defaultProps = {
  min: 0,
  max: 8,
};

export default RangeSelector;

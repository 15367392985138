import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LayoutBase } from 'libs/layout';
import { useAppSelector, USER_FEATURE_KEY, IUserState } from 'libs/data-store';
import { Container } from './layout-private.styled';

interface ILayoutPrivate {
  children: JSX.Element;
  toLoginPath: string;
  isAdmin?: boolean;
}

export function LayoutPrivate(props: ILayoutPrivate): JSX.Element {
  //console.log('🚀 ~ LayoutPrivate ~ props:', props);
  const navigate = useNavigate();
  const user: IUserState = useAppSelector((state: any) => state[USER_FEATURE_KEY]);
  const { isAdmin } = props;

  /**
   * is logined
   */
  useEffect(() => {
    if (!user.userLogined) {
      navigate(props.toLoginPath);
    } else {
      if (isAdmin && !user.admin) {
        navigate(props.toLoginPath);
      }
    }
  }, [navigate, props.toLoginPath, user.userLogined, isAdmin, user.admin]);

  return (
    <LayoutBase>
      <Container>{props.children}</Container>
    </LayoutBase>
  );
}

export default LayoutPrivate;

import { FieldProps } from 'formik';
import { ReactNode } from 'react';

import { SelectInput, SelectInputProps } from 'libs/ui';

//FieldProps & ICheckboxProps
export const SelectField = (props: FieldProps & SelectInputProps): ReactNode => {
  const { field, form, ...rest } = props;

  return <SelectInput {...field} {...rest} />;
};

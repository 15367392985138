/**
 * Form checkbox field adapter component
 *
 * @overview
 */

import { Checkbox, ICheckboxProps } from 'libs/ui';
import { FieldProps } from 'formik';
import { ReactNode } from 'react';

//FieldProps & ICheckboxProps
export const CheckboxField = (props: FieldProps & ICheckboxProps): ReactNode => {
  const {
    field,
    form: { touched, errors },
    meta,
    ...rest
  } = props;

  const name = field.name;
  const error = touched[name] && Boolean(errors[name]);
  if (error) {
    rest.color = 'error';
  }
  rest.checked = field.value;
  return <Checkbox {...field} {...rest}  />;
  
};

export default CheckboxField;

function prepareIp(ip: string): number {
  if (!ip) {
    return 0;
  }
  const ipa = ip.split(':');
  const a = ipa[0].split('.');
  let out = parseInt(a[0]) * 0x1000000 + parseInt(a[1]) * 0x10000 + parseInt(a[2]) * 0x100 + parseInt(a[3]) * 1;
  if (ipa[1]) {
    out += parseInt(ipa[1]) / 10000;
  }
  return out;
}

function prepareCharge(charge: string): number {
  if (!charge || charge === 'reading error') {
    return 0;
  }
  return parseFloat(charge);
}

function descendingComparator(a: any, b: any, orderBy: string, type: string | undefined) {
  let v1 = a[orderBy] ? a[orderBy] : '';
  let v2 = b[orderBy] ? b[orderBy] : '';
  if (type === 'ip') {
    v1 = prepareIp(v1);
    v2 = prepareIp(v2);
  }
  if (type === 'charge') {
    v1 = prepareCharge(v1);
    v2 = prepareCharge(v2);
  }
  if (v2 < v1) {
    return -1;
  }
  if (v2 > v1) {
    return 1;
  }
  return 0;
}

export function getComparator(order: IOrder, orderBy: string, field: ITableField): (a: any, b: any) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy, field.name) : (a, b) => -descendingComparator(a, b, orderBy, field.name);
}

export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

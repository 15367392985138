export const links = {
  main: '/',
  map: '/map',
  history: '/history/:id',
  events: '/events/:ip/:id',
  parkomat: '/parkomat/:id',
  login: '/login',
  tariffs: '/tariffs',
  zones: '/tariffs/zones',
  vt: '/tariffs/vt',
};

export default links;

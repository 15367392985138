import styled from '@emotion/styled';
import { Link } from "react-router-dom";
//import { css } from '@emotion/react';
//import { Link } from 'libs/layout';

export const StyledLogo = styled(Link)`
  width: 66px;
  cursor: pointer;
  font-size: 0.75rem;
  transition: 0.2s all ease-in;
  color: #fff;
  text-decoration: none;
  & img {
    max-width: 100%;
    height: auto;
  }
  margin: 0 2rem 0 0;

  &:hover {
    text-decoration: none;
    color: #d9d9d9;
  }
`;

import { Suspense } from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';

import { links } from 'libs/data-config';
import { LayoutPublic, LayoutPrivate } from 'libs/layout';
import { Loader } from 'libs/ui';
import { PageLogin, PageMain, PageParkomat, PageMap, PageTariffs, PageZones, PageVT, PageHistory, PageEvents } from './router-pages';

export function Router() {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          {/* public */}
          <Route
            element={
              <LayoutPublic toLoginedPath={links.main}>
                <Outlet />
              </LayoutPublic>
            }
          >
            <Route path={links.login} element={<PageLogin />} />
          </Route>
          {/* private */}
          <Route
            element={
              <LayoutPrivate toLoginPath={links.login}>
                <Outlet />
              </LayoutPrivate>
            }
          >
            <Route path={links.main} element={<PageMain />} />
            <Route path={links.map} element={<PageMap />} />
            <Route path={links.parkomat} element={<PageParkomat />} />
            <Route path={links.history} element={<PageHistory />} />
            <Route path={links.events} element={<PageEvents />} />            
          </Route>
          {/* private admin */}
          <Route
            element={
              <LayoutPrivate toLoginPath={links.login} isAdmin={true}>
                <Outlet />
              </LayoutPrivate>
            }
          >
            <Route path={links.tariffs} element={<PageTariffs />} />
            <Route path={links.zones} element={<PageZones />} />
            <Route path={links.vt} element={<PageVT />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default Router;

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

import { Button } from 'libs/ui';
import { Error } from './confirm.styled';

export type ConfirmProps = {
  open?: boolean;
  title: string;
  close(): void;
  info?: string;
  closeTitle?: string;
  action?: any;
  actionTitle?: string;
  error?: string;
};

export function Confirm(props: ConfirmProps) {
  const { error, title, open, info, close, action, closeTitle, actionTitle, ...rest } = props;

  const handleEntering = () => {};

  const handleCancel = () => {
    close();
  };

  const handleAction = () => {
    action();
  };

  return (
    <Dialog
      //sx={{ '& .MuiDialog-paper': { width: '95%', maxHeight: 635 } }}
      sx={{ '& .MuiDialog-paper': { padding: '10px' } }}
      //maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={Boolean(open)}
      onClose={handleCancel}
      {...rest}
    >
      <DialogTitle>{title}</DialogTitle>
      {info && <DialogContent>{info}</DialogContent>}
      {error && <Error>{error}</Error>}
      <DialogActions>
        <Button autoFocus size="medium" onClick={handleCancel}>
          {closeTitle}
        </Button>
        {actionTitle && (
          <Button size="medium" color="warning" onClick={handleAction}>
            {actionTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

Confirm.defaultProps = {
  closeTitle: 'Отмена',
};

export default Confirm;

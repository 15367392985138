/**
 * Form text field adapter component
 *
 * @overview
 */

import { TextInput, ITextInputProps } from 'libs/ui';
import { FieldProps } from 'formik';
import { ReactNode } from 'react';

export function TextField(props: FieldProps & ITextInputProps): ReactNode {
  const {
    field,
    form: { touched, errors },
    meta,
    ...rest
  } = props;

  const name = field.name;
  const error = touched[name] && Boolean(errors[name]);
  const helperText = touched[name] && errors[name];
  const HelperText = <span>{helperText?.toString()}</span>;
  //console.log('🚀 ~ TextField ~ helperText', helperText);

  return <TextInput {...field} {...rest} error={error} helperText={HelperText} />;
}

TextField.defaultProps = {
  variant: 'outlined',
  size: 'small',
};

export default TextField;

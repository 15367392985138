import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const INTERFACE_FEATURE_KEY = 'interface';

export interface IInterfaceState {
  loading: boolean;
  logined: boolean;
}

export const initialInterfaceState: IInterfaceState = {
  loading: false,
  logined: false,
};

const test = true;

if (test) {
  //initialInterfaceState.parking = '1234';
}

export const interfaceSlice = createSlice({
  name: INTERFACE_FEATURE_KEY,
  initialState: initialInterfaceState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLogined: (state, action: PayloadAction<boolean>) => {
      state.logined = action.payload;
    },
  },
});

export const interfaceReducer = interfaceSlice.reducer;
export const interfaceActions = interfaceSlice.actions;

import axios from 'axios';

//instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

class UdsApi {
  token = '';

  /**
   * set interceptors
   */
  constructor({ apiUrl, prepareRequest = null, prepareResponse = null, prepareError = null, timeout = 50000 }) {
    this.server = axios.create({
      baseURL: apiUrl,
      timeout: timeout,
      headers: {},
    });

    if (prepareRequest) {
      this.server.interceptors.request.use(prepareRequest, prepareError);
    }
    if (prepareResponse) {
      this.server.interceptors.response.use(prepareResponse, prepareError);
    }
  }

  async call(method, params, type = 'post') {
    switch (type) {
      default:
      case 'post':
        return await this.server.post(method, params);
      case 'put':
        return await this.server.put(method, params);
      case 'delete':
        return await this.server.delete(method, params);
      case 'get':
        return await this.server.get(method, params);
    }
  }

  async get(method, params) {
    return await this.call(method, { params: params }, 'get');
  }

  async post(method, params) {
    return await this.call(method, params);
  }

  async put(method, params) {
    return await this.call(method, params, 'put');
  }

  async delete(method, params) {
    return await this.call(method, params, 'delete');
  }
}

export default UdsApi;

import styled from '@emotion/styled';

export const UserContent = styled.div`
  display: flex;
  align-items: center;
`;

export const UserName = styled.div`
  margin-right: .2rem;
`;

import { createTheme } from '@mui/material/styles';

export const defaultTheme = createTheme({
  //palette: colors,
  //typography: {
  //fontFamily: "'IBM Plex Sans', sans-serif",
  //},
});

defaultTheme.components = {
  // global
  MuiCssBaseline: {
    styleOverrides: {
      'body, html': {
        //height: '100vh',
        //overflow: 'hidden',
      },
      '#root': {
        height: '100vh',
      },
    },
  },
  // table
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&.MuiTableRow-hover:hover': {
          cursor: 'pointer',
        },
        '&.with-row-click': {
          a: {
            textDecoration: 'none',
            color: 'inherit',
          },
        },
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      icon: {
        opacity: '.2',
      },
    },
  },

  // dialog
  MuiDialog: {
    styleOverrides: {
      paper: {
        //padding: '25px',
        //background: '#fff',
        //borderRadius: '8px',
        //boxShadow: '0px 8px 32px rgba(17, 15, 16, 0.08)',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        minWidth: '290px',
        padding: '20px',
        //fontWeight: 600,
        //fontSize: '38px',
        lineHeight: '1.1',
        //textAlign: 'center',
        //color: '#000000',
        //margin: '0 0 20px',
        '& .close': {
          position: 'absolute',
          right: '10px',
          top: '10px',
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '20px',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        justifyContent: 'center',
        button: {
          minWidth: '140px',
          margin: '0 15px',
        },
      },
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: {
        '&.action-cell': {
          paddingTop: '0',
          paddingBottom: '0',
        },
      },
    },
  },

  // button
  /*
  MuiButton: {
    styleOverrides: {
      root: {
        lineHeight: '1.2',
        fontSize: '1.625rem',
        padding: '.75rem 2rem',
        textTransform: 'none',
        // letterSpacing: '0.01em',
        borderRadius: '12px',
        '@media(max-width: 639px': {
          fontSize: '1.2rem',
        },
        '&$containedPrimary': {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: defaultTheme.palette.primary.dark, //'#007FC3',
          },
          '&$disabled': {
            background: defaultTheme.palette.primary.light, //'#20BFF3',
            cursor: 'not-allowed',
            color: '#fff',
          },
        },
      },
      text: {
        padding: '.75rem 2rem',
      },
      containedSizeLarge: {},
      containedSizeSmall: {},
      outlinedSizeSmall: {},
    },
  },
  */
};

export default defaultTheme;
